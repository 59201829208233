// Here you can add other styles
.text-right { text-align: right }
.text-center { text-align: center }
.text-left { text-align: left }
.padded-top { margin-top: 1em; }
.pad-right { margin-right: .5em; }
.pad-left { margin-left: .5em; }
.f-right { float: right; }
.f-left { float: left; }
.clickable { cursor: pointer; }
.clear-both { clear: both; }
.invalid-feedback { display: block!important }
.white-bg { 
    background-color: #fff; 
    color: #2A3446;
    text-align: center;
}
.red-icon { color: red; }